<template>
  <b-container :style="mainContainerStyle" class="wall pb-5 fontStandard" fluid>
    <b-row id="Smooth-dev">
      <b-col cols="0" md="1" lg="2"></b-col>
      <b-col cols="12" md="10" lg="8" class="text-center pb-5">
        <br />
        <h1 class="text-warning fontCustom">Réalisation de cette machine à sous avec Vue</h1>

        <article class="fontStandard">
          <h4>
            <strong class="arrow">Voulez-vous tenter votre chance ?</strong>
          </h4>
          <p>
            Machine à sous réalisé avec Vue, du javascript, beaucoup de CSS3, du
            php, et un stockage en base de données MySQL.
            <br />Réalisé il y a quelques temps deja, les données transitent
            avec l'api en backend
          </p>
          <MachineSlot :machine="2" center bestgain />
        </article>
      </b-col>
      <b-col cols="0" md="1" lg="2"></b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  components: { MachineSlot: () => import("@/components/MachineSlot") },
  computed: {
    mainContainerStyle() {
      return {
        height: "auto",
      };
    },
  },
};
</script>
